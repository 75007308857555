.login-dropdown {
    // transition: all 0.3s ease-out;
    display: none;
    // min-width: 150px !important;
    .auth-button {
        width: 100%;
        background: none;
        text-align: left;
        cursor: pointer;
    }

    .ant-dropdown-menu {
        padding: 10px;
        box-shadow: 0px 5px 10px rgba(15, 17, 17, 0.1);
        border-radius: 5px;
        .ant-dropdown-menu-item {
            border-bottom: 1px solid #b3b3b3;
            padding: 0;
            @include regular-font;
            color: $medium-black;
            padding: 0 0 16px;
            &:hover {
                background-color: unset;
                color: $main-blue;
                font-weight: 700;
            }
            .ant-dropdown-menu-title-content {
                width: max-content;

                .ant-menu-vertical {
                    border-right: none;
                    .ant-menu-item {
                        text-transform: capitalize;
                    }
                }
            }
        }
        .ant-dropdown-menu-item:not(:first-child) {
            padding: 16px 0px;
        }
        .ant-dropdown-menu-item:last-child {
            border: none;
            padding-bottom: 0;
        }
    }
    .menu-btn {
        width: 76px;
        height: 44px;
        background-color: $white;
        @include flex-center;
        padding: 12px;
        gap: 8px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        .seperator {
            border: 1px solid #595959;
            width: 0;
        }
    }
}
.login-dropdown.active {
    display: inline-flex;
    position: relative;
    z-index: 10;
}

.ant-dropdown-menu {
    border-radius: 5px !important;
}
.ant-dropdown-menu-item {
    padding: 0 !important;
}
