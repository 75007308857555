.menu-btn {
    min-width: 88px;
    height: 44px;
    background-color: $white;
    @include flex-center;
    padding: 12px;
    gap: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    .seperator {
        background-color: #595959;
        width: 1px;
        display: inline-block;
        height: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }
    /* Show only mobile view by default */
    .mobile-view {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .desktop-view {
        display: none;
    }

    /* Media query for desktop view */
    @media screen and (min-width: 900px) {
        /* Show desktop view and hide mobile view */
        .mobile-view {
            display: none;
        }

        .desktop-view {
            display: flex;
            align-items: center;
            gap: 8px; /* Adjust as needed */
        }
    }
    @media screen and (min-width: 1024px) {
        // min-width: 172px;
        gap: 8px;
    }
}
