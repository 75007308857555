#whatsapp-chat-widget {
  .wa-chat-box {
    img {
      height: 60%;
      width: 60%;
    }
  }
  .wa-chat-box {
    transition: scale 0.3s ease-in-out, opacity 0.3s ease-in-out;
    width: 300px;
    min-width: 300px;
    .wa-chat-box-content-chat-welcome{
      font-size: 14px;
    }
    a.wa-chat-box-content-send-btn {
      padding: 10px 20px;
      gap: 10px;
    }
  }
}