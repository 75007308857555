.navbar {
    @include flex-center;
    justify-content: space-between;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1049;
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
    .logo-container {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 15px;
        top: 15px;
        border-radius: 50%;
        @media screen and (min-width: 768px) {
            width: 100px;
            height: 80px;
            top: 8px;
            left: 30px;
        }
        @media screen and (min-width: 1024px) {
            width: 120px;
            height: 120px;
        }
        @media screen and (min-width: 1200px) {
            left: 60px;
        }
        @media screen and (min-width: 1440px) {
            left: 0;
        }
        img {
            filter: blur(0);
            object-fit: contain;
            @media screen and (min-width: 768px) {
                object-fit: cover;
            }
        }
    }
    .navbar-right {
        @include flex-center;
        justify-content: space-between;
        gap: 10px;
        position: absolute;
        right: 15px;
        top: 27px;
        @media screen and (min-width: 768px) {
            right: 30px;
        }
        @media screen and (min-width: 1024px) {
            top: 38px;
        }
        @media screen and (min-width: 1200px) {
            gap: 20px;
            right: 60px;
        }
        @media screen and (min-width: 1440px) {
            right: 0;
        }
        li {
            @include regular-font;
            line-height: 40px !important;
            .navbar-right-link {
                text-decoration: none;
                font-family: Sofia-Regular;
                color: $white;
                transition: all 0.05s ease-out;
                text-transform: capitalize;
                &:hover {
                    font-weight: 600;
                }
            }

            .navbar-right-link:after {
                display: block;
                content: '';
                border-bottom: solid 2px $white;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
            }
            .navbar-right-link:hover:after {
                transform: scaleX(1);
                color: $main-blue;
            }
            .navbar-right-link-active {
                color: $main-blue;
                font-family: Sofia-Regular;
            }
            .navbar-right-link-active:after {
                display: block;
                content: '';
                border-bottom: 2px solid $main-blue;
            }
        }
        .navbar-right-links {
            list-style: none;
            @include flex-center;
            gap: 16px;
            margin: 0;
        }
        .navbar-right_linksMobile {
            @include flex-center;
            gap: 12px;
            @media screen and (min-width: 768px) {
                gap: 24px;
            }
            @media screen and (min-width: 1200px) {
                display: none;
            }
            .navbar-right-links {
                padding: 0;
                height: 36px;
                padding-right: 12px;
                border-right: 1px solid #ffffff;
                color: $white;
                @media screen and (min-width: 768px) {
                    padding-right: 24px;
                }
                @media screen and (min-width: 1024px) {
                    padding-right: 40px;
                }
            }
        }
        .navbar-right__desktop {
            display: none;
            @media screen and (min-width: 1200px) {
                @include flex-center;
                gap: 20px;
            }

            .navbar-right__links {
                border-right: 1px solid #ffffff;
                padding-right: 20px;
                height: 40px;
                @include flex-center;

                .navbar-right-links {
                    gap: 16px;
                }
            }
            .navbar-contact {
                @include flex-center;
                gap: 12px;
                border-right: 1px solid #ffffff;
                padding-right: 20px;
                cursor: pointer;
                .whatsapp-logo-container {
                    height: 40px;
                    width: 40px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    > svg {
                        color: #fff;
                    }
                }
                .contact-number {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .contact-number-call {
                        font-family: Sofia-Regular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 140%;
                        color: $light-white;
                    }
                    .contact-number-number {
                        font-family: Sofia-Bold;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 140%;
                        color: $white;
                    }
                }
            }
        }
    }
    .accounting-nav {
        @media screen and (max-width: 1023px) {
            width: 100%;
            display: inline-block;
            position: unset;
            top: unset;
            right: unset;
        }
    }
}
.navbar.active {
    background-color: $white;
    width: 100%;
    color: $light-black;
    height: 60px;
    box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.05);
    position: sticky;
    @media screen and (min-width: 1024px) {
        height: 80px;
    }
    .padding-container {
        @include flex-center;
        justify-content: space-between;
        width: 100%;
    }
    .hide {
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
    .logo-container {
        background-color: $white;
        top: 0;
        height: 88px;
        width: 88px;
        margin-top: -20px;
        position: unset;
        img {
            filter: blur(0);
            margin-top: 5px;
            @media screen and (min-width: 768px) {
                margin-top: 10px;
            }
        }
        @media screen and (min-width: 768px) {
            height: 125px;
            width: 125px;
            margin-top: -38px;
        }
        @media screen and (min-width: 1024px) {
            height: 142px;
            width: 142px;
            margin-top: -42px;
        }
    }
    .navbar-right_linksMobile {
        @include flex-center;
        gap: 12px;
        @media screen and (min-width: 768px) {
            gap: 24px;
        }
        @media screen and (min-width: 1200px) {
            display: none;
        }
        .navbar-right-links {
            list-style: none;
            @include flex-center;
            gap: 16px;
            margin: 0;
            padding: 0;
            height: 36px;
            padding-right: 12px;
            border-right: 1px solid #ffffff;
            color: $light-black;
            @media screen and (min-width: 768px) {
                padding-right: 24px;
            }
            @media screen and (min-width: 1024px) {
                padding-right: 40px;
            }
            li {
                @include regular-font;
                color: $light-black;
                .navbar-right-link {
                    @include regular-font;
                    color: $light-black;
                    text-decoration: none;
                    text-transform: capitalize;
                }
                .navbar-right-link.active {
                    color: $main-blue;
                }

                .navbar-right-link:after {
                    display: block;
                    content: '';
                    border-bottom: solid 2px $main-blue;
                    transform: scaleX(0);
                    transition: transform 250ms ease-in-out;
                }
                .navbar-right-link:hover:after {
                    transform: scaleX(1);
                    color: $main-blue;
                }
                .navbar-right-link-active {
                    color: $main-blue;
                    font-family: Sofia-Regular;
                }
            }
        }
    }
    .navbar-right {
        position: unset;
        top: 10px;
        @media screen and (min-width: 1024px) {
            top: 19px;
        }
        li {
            @include regular-font;
            .navbar-right-link {
                text-decoration: none;
                font-family: Sofia-Regular;
                color: $white;
                transition: all 0.05s ease-out;
            }

            .navbar-right-link:after {
                display: block;
                content: '';
                border-bottom: solid 2px $main-blue;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
            }
            .navbar-right-link:hover:after {
                transform: scaleX(1);
                color: $main-blue;
            }
            .navbar-right-link-active {
                color: $main-blue;
                font-family: Sofia-Regular;
            }
            .navbar-right-link-active:after {
                display: block;
                content: '';
                border-bottom: 2px solid $main-blue;
            }
        }
        .navbar-right_linksMobile {
            .navbar-right-links {
                border-right: 1px solid $light-black;
                li {
                    color: $light-black;
                    text-transform: capitalize;
                }
                .navbar-right-link.active {
                    color: $light-black;
                }
            }
            .navbar-contact {
                border-right: 1px solid $light-black;
            }
        }
        .navbar-right__desktop {
            .navbar-right__links {
                border-right: 1px solid $light-black;
                .navbar-right-links {
                    li {
                        color: $light-black;
                        position: relative;
                        .navbar-right-link {
                            color: $light-black;
                            &.active {
                                color: $light-black;
                            }
                        }
                        .navbar-right-link:hover {
                            color: $main-blue;
                        }
                        .navbar-right-link:hover:after {
                            border-bottom: solid 2px $main-blue;
                        }
                    }
                }
            }
            .navbar-contact {
                border-right: 1px solid $light-black;
                .whatsapp-logo-container {
                    border-color: #000;
                    > svg {
                        color: #000;
                    }
                }
                .contact-number {
                    .contact-number-call {
                        color: $light-black;
                    }
                    .contact-number-number {
                        color: $main-dark-blue;
                    }
                }
            }
        }
    }
}

.navbar.active.detail-nav {
    @media screen and (min-width: 1200px) {
        height: 130px;
    }
}
.account-nav {
    width: 100%;
    @include flex-center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
    height: 60px;
    background-color: $white;

    .account-nav-left {
        @include flex-center;
        gap: 12px;
        .text {
            @include regular-font;
            color: $medium-black;
        }
    }
}
.detailsnav {
    border-radius: 5px;
    margin-block: 24px;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 101;
    padding-block: 32px !important;
    box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.05);
    width: 100%;
    gap: 12px;

    .details-wrapper {
        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
        }
    }
    .car-card__left {
        margin-bottom: 16px;
        min-width: max-content;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 0;
        @media screen and (min-width: 1024px) {
            gap: 24px;
        }
        .car-image {
            border-radius: 5px;
            overflow: hidden;
            width: 130px;
            height: 65px;
            margin-bottom: 0;
        }
        .car-deets {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 5px;
            padding-left: 12px;
            padding-right: 12px;
            border-left: 1px solid $medium-grey;
            border-right: 1px solid $medium-grey;
            gap: 13px;
            @media screen and (min-width: 1024px) {
                padding-left: 24px;
                padding-right: 24px;
            }
            .car-name {
                @include regular-font;
                color: $main-dark-blue;
                @media screen and (min-width: 768px) {
                    font-size: 20px;
                }
            }
            .car-features {
                @include flex-center;
                flex-wrap: wrap;
                min-width: max-content;
                .features-container {
                    @include flex-center;

                    gap: 5px;
                    padding-right: 5px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: #595959;
                    text-transform: capitalize;

                    @media screen and (min-width: 1024px) {
                        gap: 10px;
                        padding-right: 16px;
                    }
                    .features-icons {
                        width: 17px;
                        height: 17px;
                    }
                }
                .features-container:not(:last-child) {
                    border-right: 1px solid #b3b3b3;
                }
                .features-container:not(:first-child) {
                    // padding-left: 5px;
                    // @media screen and (min-width: 768px) {
                    padding-left: 10px;
                    // }
                    @media screen and (min-width: 1024px) {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .car-card__price {
        span {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $main-dark-blue;
            @media screen and (min-width: 768px) {
                margin-bottom: 8px;
                font-size: 20px;
            }
        }
        span:nth-child(2) {
            text-decoration: line-through;
            text-decoration-line: strikethrough;
            color: #727272;
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin-left: 8px;
        }
        p {
            font-family: Sofia-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: $dark-blue-tint1;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }
    }
    .button-container {
        @include flex-center;
        gap: 10px;
        .bottom-btn {
            width: 160px;
        }
        .whatsapp {
            @include flex-center;
            justify-content: center;
            padding: 8px 15px 8px 12px;
            gap: 10px;
            background: linear-gradient(0deg, #20b038, #20b038), #ffffff;
            color: $white;
            @include regular-font;
            border-radius: 5px;
            width: 160px;
            height: 50px;
            .whatsapp-logo-container {
                width: 24px;
                height: 24px;
            }
        }
    }
}
.details-nav {
    width: 100%;
    position: sticky;
    top: 0;
    @media screen and (min-width: 1024px) {
        @include flex-center;
    }
}
.seetails {
    width: 50px;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 100;
}
.sticky-nav {
    background-color: $white;
    height: 60px;
    font-family: Sofia-Regular;
    font-size: 16px;
    overflow-x: auto;
    width: 100%;
    ul {
        @include flex-center;
        list-style: none;
        overflow-x: scroll;
        gap: 20px;
        height: 100%;
        margin-bottom: unset;
        li {
            width: max-content;
            min-width: max-content;
            height: 100%;
            @include flex-center;
            justify-content: center;
            border-bottom: 2px solid transparent;
            color: $dark-gray;
            text-align: center;
        }

        .active {
            font-weight: 700;
            border-bottom: 2px solid $main-blue;
            color: $main-blue;
        }
    }
    ul::-webkit-scrollbar {
        display: none;
    }
}
.details-account-nav {
    @include flex-center;
    justify-content: space-between;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: $white;
    box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.05);
}
.bottom-margin {
    margin-bottom: 30px;
}
.authModal {
    z-index: 30 !important;
}

.ant-message {
    z-index: 1052 !important;
}
.navbar-item {
    position: relative;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: -20px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    display: none;

    &.show {
        display: block;
    }
}

.dropdown-item {
    padding: 0px 16px;
    display: block;
    text-decoration: none;
    color: #454545;
    min-width: max-content;

    &:hover {
        color: $main-blue;
        font-weight: 700;
    }
}

.dropdown-item-active {
    color: $main-blue;
}

.mobile-dropdown-nav {
    .dropdown-button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
        padding: 10px;
    }

    .dropdown-menu-mobile {
        position: absolute;
        top: 100%;
        left: 0;
        background: white;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        list-style: none;
        padding: 0;
        margin: 0;
        width: 150px;

        .dropdown-item {
            padding: 10px;
            display: block;
            text-decoration: none;
            color: #454545;

            &:hover {
                background: #f0f0f0;
            }
        }
    }
}

.items-center {
    display: flex;
    align-items: center;
    gap: 6px;
}

.mbl-dropdown-nav {
    @include regular-font;
    .navbar-right-link {
        text-decoration: none;
        font-family: Sofia-Regular;
        transition: all 0.05s ease-out;
        color: $main-blue;
        margin-bottom: 0;
    }
}
.mbl-dropdown-nav-active {
    font-weight: 700;
    color: $main-blue !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 0 !important;
}
