.blog-page {
    .banner-images {
        @include flex-center;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 40px;
        padding-bottom: 64px;

        gap: 8px;
        img {
            transform: scale(1);
            transition: all 0.5s ease-out;
            &:hover {
                transform: scale(1.2);
            }
        }
        .blog-type {
            width: fit-content;
            background: $main-blue;
            border-radius: 5px;
            @include regular-font;
            color: $white;
            text-align: center;
            padding: 6px 10px;
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        .banner-container {
            width: 100%;
            width: 100%;
            border-radius: 5px;
            height: 300px;
            @media screen and (min-width: 768px) {
                border-radius: 5px 0px 0px 5px;
                width: 44.167%;
                height: 550px;
            }
            .banner-image {
                overflow: hidden;
                cursor: pointer;
                border-radius: 5px;
                @media screen and (min-width: 768px) {
                    border-radius: 5px 0px 0px 5px;
                }
                .contents {
                    height: fit-content;
                }
                img {
                    border-radius: 5px 0px 0px 5px;
                }
            }
        }

        .banners-container {
            width: calc((100% - 10px) / 2);
            height: 300px;
            overflow: hidden;
            cursor: pointer;
            border-radius: 5px 0 0 5px;
            @media screen and (min-width: 768px) {
                border-radius: 0;
                width: calc((55.833% - 16px) / 2);
                height: 550px;
            }
        }
        .banner-containers {
            width: calc((100% - 10px) / 2);
            height: 300px;
            overflow: hidden;
            cursor: pointer;
            @media screen and (min-width: 768px) {
                width: calc((55.833% - 16px) / 2);
                height: 550px;
            }
            .banner-image {
                border-radius: 0px 5px 5px 0px;
                img {
                    border-radius: 0px 5px 5px 0px;
                }
            }
        }
        .banner-image {
            height: 100%;
            position: relative;
            .contents {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 16px;
                height: fit-content;
                background: linear-gradient(
                    180deg,
                    rgba(1, 1, 1, 0) 0%,
                    rgba(2, 2, 2, 0.6) 45.31%,
                    rgba(3, 3, 3, 0.77) 100%
                );
                border-radius: 0px 0px 5px 5px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                gap: 8px;
                @media screen and (min-width: 768px) {
                    padding: 24px;
                }
                .type {
                    @include bold-font;
                    font-size: 16px;
                    color: $white;
                }
                .content-title {
                    @include bold-font;
                    font-size: 16px;
                    color: $light-white;
                    @media screen and (min-width: 912px) {
                        font-size: 25px;
                    }
                }
                .author {
                    @include regular-font;
                    color: $light-white;
                }
            }
        }
    }
    .popular-blogs {
        @include flex-center;
        flex-direction: column;
        .subtitle {
            text-align: center;
        }
    }
    .follow-instagram {
        margin-bottom: 64px;
        margin-top: 64px;
        .title {
            text-align: center;
            margin-bottom: 8px;
        }
        .subtitle {
            text-align: center;
            margin-inline: auto;
        }
        .follow-sep {
            margin-inline: auto;
        }
        .insta-container {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            flex-direction: column;
            margin-top: 24px;
            justify-content: center;
            @media screen and (min-width: 600px) {
                flex-direction: row;
            }
            @media screen and (min-width: 768px) {
            }
            @media screen and (min-width: 1200px) {
                justify-content: space-between;
            }
            .image-container {
                width: 100%;
                height: 200px;
                @media screen and (min-width: 600px) {
                    width: calc((100% - 32px) / 3);
                }

                @media screen and (min-width: 1200px) {
                    width: 24%;
                }
            }
        }
        .follow-btn {
            margin-inline: auto;
            margin-top: 32px;
        }
    }
}
